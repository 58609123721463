@charset "UTF-8";
/* ==============================
		  archive.scss
================================= */
@media screen and (min-width: 960px) {
  .article {
    margin-right: 20px;
  }
}

@media screen and (min-width: 1280px) {
  .article {
    margin-right: 0;
  }
}

.article time {
  font-size: 16px;
  font-family: "Sorts Mill Goudy", serif;
}

.article h2 {
  font-size: 26px;
}

@media screen and (min-width: 960px) {
  .article h2 {
    margin-bottom: 57px;
  }
}

.pagination {
  display: flex;
  justify-content: space-between;
  margin-bottom: 72px;
}

@media screen and (min-width: 960px) {
  .pagination {
    margin-bottom: 151px;
  }
}

.pagination a {
  color: #1c1c1c;
}

.pagination .prev a::before {
  content: "";
  width: 10px;
  height: 10px;
  border-top: 1px solid #1c1c1c;
  border-right: 1px solid #1c1c1c;
  display: inline-block;
  transform: rotate(225deg);
}

.pagination .next a::after {
  content: "";
  width: 10px;
  height: 10px;
  border-top: 1px solid #1c1c1c;
  border-right: 1px solid #1c1c1c;
  display: inline-block;
  transform: rotate(45deg);
}

.share {
  margin-bottom: 70px;
}

.share ul {
  display: flex;
}

.share .facebooklink a {
  background: #1c1c1c;
  color: #fafafa;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-right: 10px;
  border-radius: 50%;
}

.share .tweet a {
  background: #1c1c1c;
  color: #fafafa;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 50%;
}

/* ==============================
		  投稿記事部分のCSS
================================= */
#blog {
  max-width: 820px;
  width: 100%;
  font-size: 14px;
  line-height: 2.5;
  margin-bottom: 38px;
}

#blog img {
  margin-bottom: 40px;
}

#blog h1 {
  font-size: 24px;
}

#blog h2 {
  font-size: 22px;
}

#blog h3 {
  font-size: 20px;
}

#blog h4 {
  font-size: 18px;
}

#blog h5 {
  font-size: 16px;
}

#blog h6 {
  font-size: 14px;
}

#blog a {
  text-decoration: underline;
  color: #1c1c1c;
}
