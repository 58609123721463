@charset "utf-8";
@import "mixin", "base";

/* ==============================
		  archive.scss
================================= */

.article {
  @include mq(m) {
    margin-right: 20px;
  }

  @include mq(xl) {
    margin-right: 0;
  }

  time {
    font-size: 16px;
    font-family: $en_font;
  }

  h2 {
    font-size: 26px;

    @include mq(m) {
      margin-bottom: 57px;
    }
  }
}


.pagination {
  display: flex;
  justify-content: space-between;
  margin-bottom: 72px;

  @include mq(m) {
    margin-bottom: 151px;
  }

  a {
    color: $black;
  }

  .prev {
    a::before {
      content: "";
      width: 10px;
      height: 10px;
      border-top: 1px solid $black;
      border-right: 1px solid $black;
      display: inline-block;
      transform: rotate(225deg);

    }
  }

  .next {
    a::after {
      content: "";
      width: 10px;
      height: 10px;
      border-top: 1px solid $black;
      border-right: 1px solid $black;
      display: inline-block;
      transform: rotate(45deg);

    }
  }
}

.share {
  margin-bottom: 70px;

  ul {
    display: flex;
  }

  .facebooklink {
    a {
      background: $black;
      color: $white;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      margin-right: 10px;
      border-radius: 50%;
    }
  }

  .tweet {
    a {
      background: $black;
      color: $white;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      border-radius: 50%;
    }
  }

}


/* ==============================
		  投稿記事部分のCSS
================================= */
#blog {
  max-width: 820px;
  width: 100%;
  font-size: 14px;
  line-height: 2.5;
  margin-bottom: 38px;

  img {
    margin-bottom: 40px;
  }

  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 22px;
  }

  h3 {
    font-size: 20px;
  }

  h4 {
    font-size: 18px;
  }

  h5 {
    font-size: 16px;
  }

  h6 {
    font-size: 14px;
  }

  pre {}

  p {}

  a {
    text-decoration: underline;
    color: $black;
  }
}
